import React, { FC } from 'react'
import dynamic from 'next/dynamic'
import MenuData from './data/menu.json'

const HeaderComponent = dynamic(() => import('../components/Header'))

interface Props {
  title: string
}

const href = '/'

const HeaderContainer: FC<Props> = ({ title }) => (
  <HeaderComponent title={title} href={href} menuItems={MenuData}/>
)

export default HeaderContainer
